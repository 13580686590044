<template>
  <div class="page_">
    <navbar className="bgf_" :showHeader="showHeader" :nowIndex="3" />
    <div></div>
    <img src="../../assets/medilink/rescue/1.png" />
    <div class="w1200_">
      <h2 class="animate">紧急救援服务</h2>
      <div class="flex_wrap_">
        <div class="img_wrap_">
          <img src="../../assets/medilink/rescue/2.png" />
        </div>
        <div class="right_wrap_ animate">
          <h4>服务内容简介</h4>
          <p>
            紧急医疗援助服务（MEA）是对旅行中遇到突发疾病、意外损伤或有医疗需求的人们提供援助服务，包括将患者转运至医疗机构救治或转运回居住地。紧急医疗援助服务的目的是为急需医疗帮助的人们提供及时与合适的解决方案，或及时安排，将他们转送到更适合的地点进行治疗。
          </p>
        </div>
      </div>
      <h2 class="animate">我们的服务</h2>
      <ul class="our_service_">
        <li>
          <div><img src="../../assets/medilink/rescue/3.png" /></div>
          <div>电话医生咨询服务</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/4.png" /></div>
          <div>医疗推荐及安排预约</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/5.png" /></div>
          <div>安排医生出诊 安排入院担保</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/6.png" /></div>
          <div>安排紧急医疗转运</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/7.png" /></div>
          <div>医疗状况跟踪 并向家属转达信息</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/8.png" /></div>
          <div>境外遗体运送回国</div>
        </li>
      </ul>
      <h2 class="animate">我们如何工作？</h2>
      <div class="flex_wrap_">
        <div class="img_wrap_">
          <div>
            <img src="../../assets/medilink/rescue/9.png" />
          </div>
          <div class="bottom_">
            <p class="light_"><span class="num_">01</span>紧急医疗援助</p>
            <p>
              中间带的紧急医疗援助服务覆盖全球，在出现紧急医疗需求时，可快速启动援助服务，根据求助车具体情况安排对应的服务。
            </p>
          </div>
        </div>
        <div class="img_wrap_">
          <div>
            <img src="../../assets/medilink/rescue/10.png" />
          </div>
          <div class="bottom_">
            <p class="light_"><span class="num_">02</span>24小时多语言服务</p>
            <p>
              中间带提供全天侯24小时，多语言援助服务。可以为在国外旅行的会员及时提的提供在线或实地紧急医疗援助服务或安排。
            </p>
          </div>
        </div>
      </div>
      <h2 class="animate">会员将享受到的福利</h2>
      <div class="flex_wrap_ member_welfare_">
        <div class="item_"><i></i>境外旅行期间的一种放心和安心感。</div>
        <div class="item_"><i></i>方便地享受中间带遍布于东南亚、中国和其他</div>
      </div>
    </div>
    <footerbar />
    <sideBar v-show="showBar"></sideBar>
  </div>
</template>
<style lang="scss" scoped>
.page_ {
  img {
    width: 100%;
    height: auto;
  }
  .w1200_ {
    width: 1200px;
    margin: 0 auto 0;
  }
  h2 {
    margin: 75px 0;
    text-align: center;
  }
  h4 {
    font-size: 18px;
    color: #000000;
    margin-bottom: 17px;
  }
  .member_welfare_ {
    padding: 42px;
    .item_ {
      margin: 59px;
      background: rgba(0, 166, 60, 0.03);
      font-size: 14px;
      border-radius: 24px;
      line-height: 48px;
      text-align: center;
    }
  }
  .flex_wrap_ {
    display: flex;
    .img_wrap_,
    .item_ {
      flex: 1;
      position: relative;
      .bottom_ {
        padding: 65px;
        position: absolute;
        left: 0;
        bottom: 0;
        .light_ {
          color: #00a63c;
          .num_ {
            font-size: 50px;
          }
        }
      }
    }
    .right_wrap_ {
      flex: 1;
      padding: 64px 50px 0 41px;
      font-size: 14px;
    }
  }
  .our_service_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      width: 155px;
    }
  }
}
</style>
<script>
import navbar from "./components/navbar";
import footerbar from "./components/footer.vue";
import sideBar from "./components/sideBar.vue";
export default {
  data() {
    return {
      showHeader: true,
      height: 0,
      showBar: false
    };
  },
  components: {
    navbar,
    footerbar,
    sideBar
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
    this.startAnimation();
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
      this.startAnimation();
    },
    startAnimation() {
      let elementArr = document.querySelectorAll(".animate");
      let bodyHeight = document.body.clientHeight;
      let heightArr = [];
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100);
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add("slideUpIn60");
        }
      }
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
